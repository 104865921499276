<template>
  <v-menu
    offset-y
    nudge-left="100"
    content-class="edit-menu"
    :close-on-content-click="true">
    <template #activator="{ on }">
      <v-icon
        medium
        color="black"
        class="brightness-icon ml-auto"
        @click="$emit('click')"
        v-on="on">
        mdi-dots-vertical
      </v-icon>
    </template>
    <v-card class="nav-card">
      <p
        @click="$emit('edit-version')">
        <img
          src="@/assets/icons/rename-icon.svg">
        Edit
      </p>
      <p
        @click="$emit('manageArchive')">
        <img
          src="@/assets/icons/archive-icon.svg">
        {{ status === 'active' ? 'Archive' : 'Unarchive' }}
      </p>
      <p
        @click="$emit('delete')">
        <img
          src="@/assets/icons/delete-icon.svg">
        Delete
      </p>
    </v-card>
  </v-menu>
</template>
<script>
export default {
  name: 'ProjectDetailsCreateIssuanceActionMenu',
  props: {
    status: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
</style>
