<template>
  <div class="">
    <v-card class="elevation-0">
      <v-card-text class="pa-0">
        <!-- list of versions -->
        <v-list>
          <v-list-item
            :class="{ 'v-item--active v-list-item--active': setSelectedClass(DEFAULT_PROJECT_VERSION) }"
            class="my-2"
            @click="selectVersion(DEFAULT_PROJECT_VERSION)">
            <div class="px-0 align-start break-word">
              <v-list-item-title class="fs-16 black--text">
                Live version
              </v-list-item-title>
            </div>
          </v-list-item>
          <v-list-item
            v-for="item in versions"
            :key="item.id"
            :class="{ 'v-item--active v-list-item--active': setSelectedClass(item.id) }"
            class="my-2"
            @click="selectVersion(item.id)">
            <div class="px-0 align-start break-word">
              <v-list-item-title class="d-flex align-center fs-16 black--text">
                <img
                  v-if="item.status === ARCHIVED"
                  class="mr-1"
                  width="16"
                  height="16"
                  src="@/assets/icons/archive-icon.svg">
                {{ item.title }}
              </v-list-item-title>
              <div v-if="item.shortName">
                {{ item.shortName }}
                {{ item.versionDate }}
              </div>
            </div>
            <ActionMenu
              :status="item.status"
              @click="actionClick(item.id)"
              @manageArchive="manageArchiving"
              @delete="deleteVersion"
              @edit-version="editVersionAction({...item})" />
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="border-dashed"
          block
          text
          @click="createVersion">
          <v-icon
            color="blue"
            size="25">
            mdi-plus-circle
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- dialog to create/edit versions -->
    <app-dialog
      v-model="isInputCaptureVersionFocused"
      width="440">
      <v-card>
        <v-card-title>
          Capture Version
          <span
            class="sourcery__icon-wrapper black"
            @click="closeEditVersionAction">
            <v-icon
              color="white"
              size="15">
              mdi-close
            </v-icon>
          </span>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="version.title"
            single-line
            dense
            clearable
            counter
            maxlength="50"
            data-test="issuance_capture_version_input"
            autocomplete="off"
            class="mt-5 v-text-field__rounded">
            <template #label>
              Capture version
            </template>
          </v-text-field>
          <v-text-field
            v-model="version.shortName"
            single-line
            clearable
            dense
            data-test="issuance_short_name_input"
            counter
            maxlength="10"
            autocomplete="off"
            class="mt-6 v-text-field__rounded">
            <template #label>
              Short name
            </template>
          </v-text-field>
          <app-date-picker
            :menu-value="menuDate"
            :date-picker-value="version.versionDate"
            data-test="issuance_date_input"
            @on-date-picker-value-event="setVersionDate"
            @on-cancel="menuDate = false;"
            @on-save="menuDate = false;" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            v-if="!editVersion"
            :disabled="version.title === ''"
            color="blue"
            class="white--text"
            elevation="0"
            @click="createProjectVersion">
            Create version
          </v-btn>
          <template v-else>
            <v-btn
              color="blue"
              class="white--text"
              elevation="0"
              :disabled="!isEditedVersion"
              @click="editVersionRequest">
              Edit
            </v-btn>
            <v-btn
              outlined
              @click="closeEditVersionAction">
              Cancel
            </v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </app-dialog>
  </div>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapGetters,
  mapState,
} from 'vuex';

import {
  ARCHIVED, ACTIVE,
} from '@/constants';
import { DEFAULT_PROJECT_VERSION } from '@/constants/scheduleViews';

import ActionMenu from './ActionMenu';
import AppDatePicker from '@/components/App/AppDatePicker';

import ProjectVersionsApi from '@/services/graphql/projectVersions';

export default {
  name: 'ProjectDetailsCreateIssuance',
  components: {
    ActionMenu, AppDatePicker,
  },
  data() {
    return {
      ARCHIVED,
      DEFAULT_PROJECT_VERSION,
      actionedVersionId: null,
      clonedVersion: {
        title: '',
        shortName: '',
        versionDate: null,
      },
      editVersion: false,
      isEditedVersion: false,
      isInputCaptureVersionFocused: false,
      menuDate: false,
      version: {
        title: '',
        shortName: '',
        versionDate: null,
      },
    };
  },
  computed: {
    ...mapState({
      activeWorkspaceId: state => state.Workspace.activeWorkspaceId,
      listOfVersions: state => state.ProjectVersions.listOfVersions,
    }),
    ...mapGetters({
      getCurrentVersion: 'ProjectVersions/getCurrentVersion',
    }),
    versions: {
      get() {
        return this.listOfVersions;
      },
      set(val) {
        this.setListOfVersions(val);
      },
    },
    currentVersion: {
      get() {
        return this.getCurrentVersion;
      },
      set(val) {
        this.setCurrentVersion(val);
      },
    },
    id() {
      return this.$route.params.id;
    },
  },
  watch: {
    version: {
      handler() {
        if (!this.editVersion) return;
        this.isEditedVersion = !this.lodash.isEqual(this.version, this.clonedVersion);
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      setListOfVersions: 'ProjectVersions/setListOfVersions',
      setCurrentVersion: 'ProjectVersions/setCurrentVersion',
    }),
    ...mapActions({
      handleError: 'handleError',
    }),
    actionClick(id) {
      if (!this.editVersion) this.actionedVersionId = id;
    },
    async createProjectVersion() {
      this.spinner(true);
      try {
        const { data } = await ProjectVersionsApi.createProjectVersion({
          projectId: this.id,
          workspaceId: this.activeWorkspaceId,
          ...this.version,
        });
        const { id } = data.response;

        this.selectVersion(id, true);

        this.versions = [
          ...this.versions, data.response,
        ];
      } catch (err) {
        this.handleError(err);
      } finally {
        this.isInputCaptureVersionFocused = false;
        this.resetVersion();
        this.spinner(false);
        this.$emit('closeDrawer');
      }
    },
    createVersion() {
      this.isInputCaptureVersionFocused = true;
      this.resetVersion();
    },
    closeEditVersionAction() {
      this.isInputCaptureVersionFocused = false;
      this.editVersion = false;
      this.resetVersion();
      this.clonedVersion = this.version;
    },
    editVersionAction(props) {
      this.editVersion = true;
      this.version = props;
      this.clonedVersion = this.lodash.cloneDeep(this.version);
      this.isInputCaptureVersionFocused = true;
    },
    async editVersionRequest() {
      this.spinner(true);
      try {
        const { data } = await ProjectVersionsApi.updateProjectVersion({
          projectId: this.id,
          versionId: this.actionedVersionId,
          workspaceId: this.activeWorkspaceId,
          ...this.version,
        });
        const { title, shortName, versionDate, id } = data.response;

        if (this.versions.length) {
          this.versions = this.versions.map((item) => ({
            ...item,
            ...item.id === id
              ? {
                title,
                shortName,
                versionDate,
              }
              : {
              },
          }));
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
        this.closeEditVersionAction();
      }
    },
    async deleteVersion() {
      this.spinner(true);
      try {
        const { data } = await ProjectVersionsApi.deleteProjectVersion({
          projectId: this.id,
          versionId: this.actionedVersionId,
          workspaceId: this.activeWorkspaceId,
        });
        this.actionedVersionId = null;
        const { id } = data.response;
        this.versions = this.versions.filter(version => version.id !== id);
        if (this.currentVersion === id) {
          this.selectVersion(DEFAULT_PROJECT_VERSION);
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async manageArchiving() {
      this.spinner(true);
      const { status } = this.versions.find(version => version.id === this.actionedVersionId);
      const { id: projectId, activeWorkspaceId: workspaceId, actionedVersionId: versionId } = this;
      const variables = {
        projectId,
        versionId,
        workspaceId,
      };
      const isActiveProject = status === ACTIVE;
      try {
        const { data } = isActiveProject ?
          await ProjectVersionsApi.archiveProjectVersion(variables) :
          await ProjectVersionsApi.unarchiveProjectVersion(variables);
        const { status, id } = data.response;
        if (this.versions.length) {
          this.versions = this.versions.map((item) => ({
            ...item,
            status: item.id === id ? status : item.status,
          }));
        }
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    resetVersion() {
      this.version.title = '';
      this.version.shortName = '';
      this.version.versionDate = null;
    },
    setSelectedClass(id) {
      return id === this.currentVersion;
    },
    selectVersion(id, isNewVersion = false) {
      if (id === this.currentVersion) return;
      this.currentVersion = id;

      this.$router.replace({
        query: {
          ...this.$route.query,
          version: id === DEFAULT_PROJECT_VERSION ? undefined : id,
        },
      });

      this.$emit('updateVersionRow', {
        id: this.currentVersion,
        isNewVersion,
      });
      this.$emit('closeDrawer');
    },
    setVersionDate(date) {
      this.version.versionDate = date;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-dashed {
  border-radius: 10px;
  border: 1px rgba(12, 12, 12, 0.25) dashed;
}
</style>
